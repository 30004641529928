

































































































































import AuthModule from '@/app/auth/store';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ApartmentDto from '../dto/apartment.dto';

@Component
export default class ApartmentCard extends Vue {
  @Getter
  private isHostAdmin!: typeof AuthModule.prototype.isHostAdmin;

  @Getter
  private isHost!: typeof AuthModule.prototype.isHost;

  @Prop({ type: Object, required: true })
  private apartment!: ApartmentDto;

  @Emit('delete')
  onDelete() {
    return this.apartment;
  }
}
