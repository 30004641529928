






















































































































































































import { Getter } from 'vuex-class';
import { Component, Emit, Prop } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import DatatableMixin from '@/app/core/mixins/datatable.mixin';
import ApartmentDto from '../dto/apartment.dto';

@Component
export default class ApartmentsDataTable extends DatatableMixin {
  @Getter
  private isHost!: typeof AuthModule.prototype.isHost;

  @Getter
  private isHostAdmin!: typeof AuthModule.prototype.isHostAdmin;

  @Prop({ type: Boolean, default: true }) viewList!: boolean;

  declare items: ApartmentDto[];

  headers = [
    {
      text: this.$t('label.name').toString(),
      value: 'name',
    },
    {
      text: this.$t('label.location').toString(),
      value: 'location',
    },
    {
      text: this.$t('label.rooms').toString(),
      value: 'rooms',
    },
    {
      text: this.$t('label.actions').toString(),
      value: 'actions',
      class: 'visible-on-mobile-only',
    },
  ];

  @Emit('changeView')
  onChangeView() {
    return this.viewList;
  }

  @Emit('delete')
  onDelete(apartment: ApartmentDto) {
    return apartment;
  }
}
