


















import { Component } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ConfirmationDialog from '@/app/core/components/confirmation-dialog.vue';
import ApartmentDto from '../dto/apartment.dto';
import ListApartmentsDto from '../dto/list-apartments.dto';
import apartmentsService from '../services/apartments.service';
import ApartmentsCards from '../components/apartments-cards.vue';
import ApartmentsDataTable from '../components/apartments-data-table.vue';

@Component({
  metaInfo(this: Apartments) {
    return { title: this.$t('pageTitle.apartments').toString() };
  },
  components: {
    ApartmentsCards,
    ApartmentsDataTable,
  },
})
export default class Apartments extends HandlesErrorMixin {
  private loading = true;
  private viewList = true;
  private apartments: ApartmentDto[] = [];
  private totalApartments = 0;
  private filters: ListApartmentsDto = {
    q: '',
    page: 1,
    itemsPerPage: 10,
  };

  get view() {
    return this.viewList ? ApartmentsDataTable : ApartmentsCards;
  }

  onSearch(q: string) {
    this.filters.q = q;

    this.loadApartments();
  }

  async onDelete({ id, name }: ApartmentDto) {
    const title = this.$t('title.deleteApartment');
    const message = this.$t('message.confirmDeleteApartment', { name });
    const confirm = await this.$dialog.open(ConfirmationDialog, { title, message });

    if (confirm) {
      try {
        await apartmentsService.deleteApartment(id as string);
        this.loadApartments();
        this.$notify.success(this.$t('success.apartmentDeleted').toString());
      } catch (err) {
        this.handleError(err);
      }
    }
  }

  onPageChange(page: number) {
    this.filters.page = page;

    this.loadApartments();
  }

  onUpdateItemsPerPage(itemsPerPage: number) {
    this.filters.page = 1;
    this.filters.itemsPerPage = itemsPerPage;

    this.loadApartments();
  }

  async loadApartments() {
    this.loading = true;

    try {
      const response = await apartmentsService.listApartments(this.filters);

      this.apartments = response.data.items;
      this.totalApartments = response.data.totalItems;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.loadApartments();
  }
}
