




















































































import { Getter } from 'vuex-class';
import { Component, Emit, Prop } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import DatatableMixin from '@/app/core/mixins/datatable.mixin';
import ApartmentDto from '../dto/apartment.dto';
import ApartmentCard from './apartment-card.vue';

@Component({
  components: {
    ApartmentCard,
  },
})
export default class ApartmentsCards extends DatatableMixin {
  @Getter
  private isHostAdmin!: typeof AuthModule.prototype.isHostAdmin;

  @Prop({ type: Boolean, default: true }) viewList!: boolean;

  items!: ApartmentDto[];

  @Emit('changeView')
  onChangeView() {
    return this.viewList;
  }

  @Emit('delete')
  onDelete(apartment: ApartmentDto) {
    return apartment;
  }
}
