var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2 disable-sort",attrs:{"disable-sort":"","page":_vm.page,"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"footer-props":{ 'items-per-page-options': _vm.itemsPerPageOptions }},on:{"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.search),expression:"search"}],staticClass:"search-input",attrs:{"dense":"","outlined":"","hide-details":"","color":"secondary","append-icon":"mdi-home-search","label":_vm.$t('label.search')}})],1),_c('v-col',{staticClass:"d-flex justify-end flex-nowrap",attrs:{"cols":"12","md":"3"}},[(_vm.isHostAdmin)?_c('v-btn',{staticClass:"d-flex flex-grow-1 flex-md-grow-0 mr-3",attrs:{"color":"secondary","to":"/apartments/new"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-home-plus")]),_vm._v(" "+_vm._s(_vm.$t('btn.newApartment'))+" ")],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex",attrs:{"icon":"","outlined":"","color":"secondary"},on:{"click":_vm.onChangeView}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.viewList ? 'mdi-view-grid' : 'mdi-view-list')+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.viewList ? 'tooltip.gridView' : 'tooltip.listView'))+" ")])])],1)],1)]},proxy:true},{key:"item.location",fn:function(ref){
var item = ref.item;
return [(item.location.address)?[_vm._v(" "+_vm._s(item.location.address)+" ")]:_c('v-chip',{staticClass:"mt-2",attrs:{"small":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"error"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(_vm.$t('label.noAddress'))+" ")],1),_c('br'),(item.location.city)?[_c('span',{staticStyle:{"color":"rgba(0, 0, 0, 0.6)"}},[_vm._v(" "+_vm._s(item.location.city)+", ")])]:[_c('v-chip',{staticClass:"mt-1 mb-2",attrs:{"small":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"error"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(_vm.$t('label.noCity'))+" ")],1),_vm._v(", ")],(item.location.country)?[_c('span',{staticStyle:{"color":"rgba(0, 0, 0, 0.6)"}},[_vm._v(" "+_vm._s(item.location.country)+" ")])]:_c('v-chip',{staticClass:"mt-1 mb-2",attrs:{"small":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"error"}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(_vm.$t('label.noCountry'))+" ")],1)]}},{key:"item.rooms",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0 mb-n5"},[_c('v-list-item-avatar',{staticClass:"ma-0"},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-bed ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$tc('label.bedrooms', item.rooms.bedrooms))+" ")])],1)],1),_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-avatar',{staticClass:"ma-0"},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-shower ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$tc('label.bathrooms', item.rooms.bathrooms))+" ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary","to":("/apartments/details/" + (item.id))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-details")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.details')))])]),(_vm.isHost)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary","to":("/apartments/update/" + (item.id))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.edit')))])]):_vm._e(),(_vm.isHostAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.onDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.remove')))])]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"pa-4 text-center text--disabled"},[_vm._v(" "+_vm._s(_vm.$t('label.noData'))+" ")])]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"pa-4 text-center text--disabled"},[_vm._v(" "+_vm._s(_vm.$t('label.loading'))+" ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }